<template>
  <base-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="priceRequestList"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
      >
        <template v-slot:item.status="{item}">
          <v-chip
            v-if="!isAvilableToReply(item)"
            class="px-4"
            color="orange darken-4 white--text"
          >
            <v-icon left>
              mdi-account-clock
            </v-icon>
            {{ item.status }}
          </v-chip>
        </template>

        <template v-slot:item.action="{item}">
          <v-btn
            v-if="isAvilableToReply(item)"
            class="ma-1"
            small
            color="primary"
            @click.native="replyRequest(item)"
          >
            {{ $t("price.reply") }}
            <v-icon class="ml-1">mdi-reply</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  import TagGroup from '../components/TagGroup'

  export default {
    metaInfo: {
      title: 'Request Price',
    },

    components: {
      TagGroup,
    },

    data () {
      return {
        priceRequestList: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        headers: [],
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        isAdminUser: 'isAdminUser',
      }),
    },

    watch: {
      currentPage(val) {
        this.searchAccesses(val)
      }
    },

    mounted () {
      if (this.isAdminUser) {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('partner.companyName'),
          value: 'partnerName',
        }, {
          text: this.$t('price.customerName'),
          value: 'customerName',
        }, {
          text: this.$t('common.email'),
          value: 'email',
        }, {
          text: this.$t('address.address'),
          value: 'address',
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 100,
        }]
      } else {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('price.customerName'),
          value: 'customerName',
        }, {
          text: this.$t('common.email'),
          value: 'email',
        }, {
          text: this.$t('address.address'),
          value: 'address',
        }, {
          text: this.$t('common.status'),
          value: 'status',
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 100,
        }]
      }

      this.fetchRequestList()
    },

    methods: {
      ...mapActions({
        fetchPriceRequestList: 'fetchPriceRequestList',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async fetchRequestList(currentPage = 1) {
        this.currentPage = currentPage

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const payload = {
            offset: (currentPage - 1) * this.limit,
            limit: this.limit,
          }

          const response = await this.fetchPriceRequestList(payload)

          this.totalPages = Math.ceil(response.count / this.limit)
          this.priceRequestList = response.rows.map((priceRequest, index) => {
            priceRequest.index = (this.currentPage - 1) * this.limit + index + 1
            priceRequest.createdAt = util.formatDateTime(priceRequest.createdAt)

            priceRequest.address = util.getFullAddress({
              street: priceRequest.street,
              street_number: priceRequest.number,
              letter: priceRequest.littera,
              locality: priceRequest.city,
              postalcode: priceRequest.postalCode,
            })

            const requestUser = priceRequest.requestUser
            const replyUser = priceRequest.replyUser

            priceRequest.customerName =
              requestUser && `${requestUser.firstName} ${requestUser.lastName}`
            priceRequest.partnerName = requestUser?.partner?.companyName
            priceRequest.email =
              priceRequest.requestUser && priceRequest.requestUser.email
            priceRequest.status = !priceRequest.replyId ? this.$t('price.waitingAdmin') : ''

            return priceRequest
          })
        } catch (error) {
          this.totalPages = 1
          this.tableData = []
          this.currentPage = 1
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      isAvilableToReply(request) {
        if (this.isAdminUser) {
          return true
        } else {
          return request.replyId && request.replyId !== this.currentUser.id
        }
      },

      replyRequest(row) {
        this.$router.push(`/reply-request?requestId=${row.id}`)
      },

      resetForm() {
        this.errorMessage = ''
        this.city = ''
        this.street = ''
        this.streetNumber = ''
        this.tableData = []
        this.currentPage = 1
        this.totalPages = 1
      },
    },
  }
</script>
